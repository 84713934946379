<template>
  <div class="d-flex details" :class="{reverse}">
    <div class="user-img" :class="{hasBackground: user.profile_image}" :style="{backgroundImage: `url(${user.profile_image || ''})`}">
      <slot v-if="!user.profile_image">
        <div class="initials">{{ getInitials(user.username) }}</div>
      </slot>
    </div>
    <div class="info">
      <slot name="info"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./UserImageOnly.scss";
</style>

<script>
export default {
  props: {
    user: Object,
    reverse: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getInitials(name) {
      return name.slice(0, 2);
    },
  },
};
</script>
